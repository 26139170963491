import http from '../clients/Axios'

export default class ReportService {
  async penerimaan(periode_from, periode_to) {
    const params = new URLSearchParams()

    if (periode_from) {
      params.append('filter[periode_from]', periode_from)
    }

    if (periode_to) {
      params.append('filter[periode_to]', periode_to)
    }

    return http({
      url: `/report/penerimaan`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async pengeluaran(periode_from, periode_to) {
    const params = new URLSearchParams()

    if (periode_from) {
      params.append('filter[periode_from]', periode_from)
    }

    if (periode_to) {
      params.append('filter[periode_to]', periode_to)
    }

    return http({
      url: `/report/pengeluaran`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async jurnalUmum(periode_from, periode_to) {
    const params = new URLSearchParams()

    if (periode_from) {
      params.append('filter[periode_from]', periode_from)
    }

    if (periode_to) {
      params.append('filter[periode_to]', periode_to)
    }

    return http({
      url: `/report/jurnal-umum`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
}
