<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import { useWindowSize } from '@vueuse/core'

const { width } = useWindowSize()
const isMobile = computed(() => width.value <= 450)
const isTitle = computed(() => props.displayTitle == true)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle', 'export'])

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: 'Page Title',
  },
  displayTitle: {
    type: Boolean,
    default: true,
  },
})
</script>

<template>
  <div v-if="isTitle">
    <Button
      v-if="!isMobile"
      icon="pi pi-bars"
      class="p-button-text mr-2"
      @click="$emit('toggle')"
    />
    <span class="text-2xl font-bold">{{ title }}</span>
  </div>
  <div class="flex justify-content-end mb-3">
    <!-- <Button
      :loading="loading"
      icon="pi pi-file-pdf"
      label="Export"
      @click="$emit('export')"
    /> -->
  </div>
  <slot name="content"></slot>
</template>
